import React, { useState } from 'react'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import QrCode from '../../assets/image/qrcodeMundoGamer.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { widths } from 'src/styles/breakpoints'
import OrangeDs from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section, Button, SectionMundoGamer } from './style'
import useWidth from 'src/hooks/window/useWidth'

const Hero = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth(300)

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal isModal={isOpen} setIsModal={setIsOpen} qrCode={QrCode} instructions='Se ainda não é cliente Inter, o QR Code vai direcionar você para a loja de aplicativos. Aí é só baixar o Super App e abrir sua conta digital e gratuita.' title='Acesse o <span class="text-orange--extra">Super App</span> e aproveite vantagens exclusivas para turbinar seu mundo gamer' />
    </Modal>
  )

  return (
    <>
      <SectionMundoGamer className='bg-grayscale--500 d-none d-xl-block'>
        <h1 className='fs-14 fw-700 text-white text-center'><OrangeDs icon='game' size='MD' color='#FFB46E' className='mr-xl-2 mb-xl-1' />Mundo Gamer Inter</h1>
      </SectionMundoGamer>
      <Section
        className='py-5 d-flex align-items-center'
        role='img'
        aria-label='Jovem usando fone de ouvido e PC gamer para jogar os melhores games.'
      >
        {modal}
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <h2 className='fs-32 lh-40 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-4'>
                Melhores games e tudo que você precisa para sua gameplay
              </h2>
              <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-white'>Da cadeira ao pc gamer. O Inter tem tudo para transformar seu mundo gamer com muito cashback e vantagens exclusivas.</p>
              {
              width < widths.md ? (
                <a
                  href='https://intergo.app/2f359910'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Acesse o Super App',
                      section_name: 'Melhores games e tudo que você precisa para sua gameplay.',
                      redirect_url: 'https://intergo.app/2f359910',
                    })
                  }}

                >
                  Acesse o Super App
                </a>
              ) : (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Acesse o Super App',
                      section_name: 'Melhores games e tudo que você precisa para sua gameplay.',
                    })
                  }
                }
                >
                  Acesse o Super App
                </Button>

              )
            }
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default Hero
