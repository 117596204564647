import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'
import BgMd from '../../assets/image/whateverYourGamingStyleMd.png'
import BgLg from '../../assets/image/whateverYourGamingStyleLg.png'
import BgXl from '../../assets/image/whateverYourGamingStyleXl.png'

export const Section = styled.section`
  background: #161616 ;

  a{
    background: ${orange.extra};
    border-radius: 8px;
    width: 100%;
    border: none;
    height: 48px;
    color: ${white};
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

  @media(min-width: ${breakpoints.md}){
    width: 265px;
  }


  &:focus{
    outline: none;
  }
  }


  @media(min-width: ${breakpoints.md}){
    background-image: url(${BgMd});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 499px;
    background-position-x: right;
  }

  @media(min-width: ${breakpoints.lg}){
    background-image: url(${BgLg});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 561px;
    background-position-x: right;
  }

  @media(min-width: ${breakpoints.xl}){
    background-image: url(${BgXl});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 706px;
    background-position-x: right;
  }
`
export const Button = styled.button`
  background: ${orange.extra};
  border-radius: 8px;
  width: 100%;
  border: none;
  height: 48px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 24px;

  @media(min-width: ${breakpoints.md}){
    width: 265px;
  }


  &:focus{
    outline: none;
  }
`
