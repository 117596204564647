import React, { useState, ChangeEvent } from 'react'
import AcceptTerms from 'src/components/AcceptTerms'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLs from 'src/config/api/Urls'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import { sendCDPFormData } from 'src/shared/helpers'

import success from '../../assets/image/success.png'
import error from '../../assets/image/error.png'

import { Section, Card, Button } from './style'
import CardResponse from './card/_index'

interface IFormValues {
  nome: string;
  email: string;
  cpfCnpj: string;
  conteudo01: string;
}

const StayOnTopOfTheNews = () => {
  const [ accept, setAccept ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ type, setType ] = useState<'form' | 'success' | 'error'>('form')
  const [ sendDatalayerEvent ] = useDataLayer()

  const formSubmit = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      cpfCnpj: data.cpfCnpj.replace(/\D/g, ''),
      aceite: accept,
      campanha: 'Semana Gamer 2023',
    }

    try {
      await axios.post(`${URLs.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      sendDatalayerEvent({
        section: 'dobra_07',
        element_action: 'submit',
        element_name: 'Avise-me',
        section_name: 'Fique por dentro das novidades e ofertas da Semana Gamer 2023 antes de todo mundo!',
        step: '1',
      })
      sendCDPFormData({ formName: 'Semana Gamer 2023', cpf: data.cpfCnpj, email: data.email })
      setType('success')
    } catch (err) {
      setLoading(false)
      setType('error')
    }
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>
              Fique por dentro das novidades e ofertas da{' '}
              <span className='text-white'>Semana Gamer 2024</span> antes de todo mundo!
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400'>
              Cadastre e tenha acesso a lives exclusivas, ofertas e <strong>gift cards de jogos</strong> das plataformas mais acessadas do mundo!
            </p>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            {
              type === 'error' && (
                <CardResponse
                  image={error}
                  text='Houve um erro no envio do seu cadastro'
                  buttonText='Tentar novamente' secondery
                  action={setType}
                  step='error'
                />
              )
            }
            {
              type === 'success' && (
                <CardResponse
                  image={success}
                  text='Seu e-mail foi cadastrado com sucesso'
                  buttonText='Cadastrar novo e-mail'
                  action={setType}
                  step='success'
                />
              )
            }
            {
              type === 'form' && (
                <Card>
                  <form onSubmit={handleSubmit(formSubmit)}>
                    <div className='mb-2'>
                      <label className='fs-14 lh-17 text-grayscale--400'>Nome</label>
                      <input
                        type='text'
                        placeholder='Digite seu nome completo'
                        name='nome'
                        ref={register({
                        required: 'Por favor, digite seu nome completo',
                        validate: {
                          isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                        },
                      })}
                      />
                      {errors.nome && <p className='fs-12  mb-0 text-right'>{errors.nome.message}</p>}
                    </div>
                    <div className='mb-2'>
                      <label className='fs-14 lh-17 text-grayscale--400'>E-mail</label>
                      <input
                        type='text'
                        placeholder='Digite seu e-mail'
                        name='email'
                        ref={register({
                        required: 'Digite um e-mail válido',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'E-mail inválido',
                        },
                      })}
                      />
                      {errors.email && <p className='fs-12  mb-0 text-right'>{errors.email.message}</p>}
                    </div>
                    <div>
                      <label className='fs-14 lh-17 text-grayscale--400'>CPF</label>
                      <input
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpfCnpj', Masks.MaskCPF(event.currentTarget.value))}
                        type='text'
                        placeholder='Digite seu CPF'
                        name='cpfCnpj'
                        ref={register({
                        required: 'Digite um CPF ou CNPJ válido',
                        validate: {
                          isCpf: (value: string) => Validations.cpforcnpj(value) || 'CPF ou CNPJ Inválido',
                        },
                      })}
                      />
                      {errors.cpfCnpj && <p className='fs-12  mb-0 text-right'>{errors.cpfCnpj.message}</p>}
                    </div>
                    <AcceptTerms accept={accept} setAccept={setAccept} name='acceptTerms' />
                    <Button
                      type='submit'
                      disabled={!accept || loading}
                    >Avise-me
                    </Button>
                  </form>
                </Card>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default StayOnTopOfTheNews
