import styled from 'styled-components'
import { white, orange } from 'src/styles/colors'

export const CardForm = styled.div`
  background: ${white};
  border-radius: 16px;
  width: 100%;
  min-height: 415px;
  padding: 24px;
  display: flex;
  align-items: flex-end;

  img{
    width: 64px;
    height: 64px;
    margin: 0 auto 24px;
  }

`
export const Button = styled.button`
  background: ${white} ;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid ${orange.extra};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 80px;
  color: ${orange.extra} ;

  &:focus{
    outline: none;
  }
`
export const ButtonSecondery = styled.button`
  background: ${orange.extra} ;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid ;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;
  color: ${white};
  margin-top: 80px;

  &:focus{
    outline: none;
  }
`
