import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'
import blogJSON from '../../assets/data/_blog'

// types
type blogProps = {
  image: string;
  title: string;
  link: string;
}

const TipsForYourGamerWorld = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32   lh-md-40 text-white mb-3'>Dicas para o seu mundo gamer</h2>
          </div>
          {
            blogJSON.map((item: blogProps) => (
              <div className='col-12 col-md-6 col-xl-3' key={item.title}>
                <a
                  href={item.link}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click banner',
                      element_name: item.title,
                      section_name: 'Dicas para o seu mundo gamer',
                      redirect_url: item.link,
                    })
                  }
                  }
                >
                  <img className='image' src={item.image} alt={item.title} />
                  <figcaption className='card-texts'>
                    <h3 className='fs-20 lh-25 text-white fw-600 my-4'>{item.title}</h3>
                  </figcaption>
                </a>
              </div>
            ))
          }
        </div>
      </div>
    </Section>
  )
}

export default TipsForYourGamerWorld
