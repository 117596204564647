import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { CardForm, Button, ButtonSecondery } from './style'

type CardFormProps = {
  image: string;
  text: string;
  buttonText: string;
  secondery?: boolean;
  action: Function;
  step: string;
}

const CardResponse = ({ image, text, buttonText, secondery, action, step }: CardFormProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <CardForm>
      <div className='text-center'>
        <img src={image} />
        <h3
          className='fs-20 lh-25 fs-md-24 lh-md-30 text-grayscale--500 fw-600 text-center mb-0'
        >
          {text}
        </h3>
        {
          secondery ? (
            <ButtonSecondery
              onClick={() => {
              action('form')
              sendDatalayerEvent({
                section: 'dobra_05',
                element_action: 'click button',
                element_name: buttonText,
                section_name: 'Fique por dentro das novidades e ofertas da Semana Gamer 2023 antes de todo mundo!',
                step: step,
              })
              }}
            >
              {buttonText}
            </ButtonSecondery>
            ) : (
              <Button
                onClick={() => {
                  action('form')
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: buttonText,
                    section_name: 'Fique por dentro das novidades e ofertas da Semana Gamer 2023 antes de todo mundo!',
                    step: step,
                  })
                }}
              >
                {buttonText}
              </Button>
            )
        }
      </div>
    </CardForm>
  )
}

export default CardResponse
