import React from 'react'

import { Section } from './style'
import faqData from '../../pageContext.json'

// Components
import Faq from 'src/components/Faq'

const FAQ = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 mb-5 text-white fw-600'>Perguntas frequentes</h2>
          </div>
          <Faq
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            answerData={faqData.structuredData.faq}
            className='text-white'
            questionColor='text-white'
            answersColor='#ffffff'
          />
        </div>
      </div>
    </Section>
  )
}

export default FAQ
