import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: #161616;

  a{
    background: ${orange.extra};
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: ${white};
    border: none;
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;


 @media(min-width: ${breakpoints.lg}){
   width: 265px;
 }


  &:focus {
    outline: none;
  }
  }


`
export const Button = styled.button`
  background: ${orange.extra};
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${white};
  border: none;
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
  margin-top: 24px;


 @media(min-width: ${breakpoints.lg}){
   width: 265px;
 }


  &:focus {
    outline: none;
  }
`
