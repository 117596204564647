import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${orange.extra} ;
`
export const Card = styled.div`
  background: ${white};
  border-radius: 16px;
  width: 100%;
  min-height: 415px;
  padding: 24px;

  input{
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background: #F5F6FA;
    border: none;
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: #B6B7BB;

    &:focus{
      outline: none;
    }

  }

`
export const Button = styled.button`
  background: ${orange.extra};
  border-radius: 8px;
  width: 100%;
  border: none;
  height: 48px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;


  &:focus{
    outline: none;
  }
`
