import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import cardJson from '../../assets/data/_card'

import { Section, Card, Button } from './style'

type CardProps = {
  image: string;
  text: string;
  link: string;
}

const UpgradeYourSetup = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white text-center mb-4'>Dê um up no seu setup gamer e <span className='text-orange--extra'>ganhe cashback</span></h2>
          </div>
          <div className='col-12'>
            <DefaultCarousel sm={{ items: 2 }} md={{ items: 4 }} lg={{ items: 4 }} xl={{ items: 5 }} itemClass='px-1'>
              {
                cardJson.map((item: CardProps, index: number) => (
                  <Card key={item.text} image={item.image}>
                    <p className='fs-16 lh-20 fs-lg-24 lh-lg-30 text-white fw-600 text-center'>{item.text}</p>
                    <Button
                      href={item.link}
                      target='_blank'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: `dobra_03, p_${index + 1}`,
                          element_action: 'click button',
                          element_name: item.text,
                          section_name: 'Dê um up no seu setup gamer e ganhe cashback',
                          redirect_url: item.link,
                        })
                      }
                      }
                    >
                      Abrir ofertas
                    </Button>
                  </Card>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default UpgradeYourSetup
