import React, { useState } from 'react'
import pageQuery from '../../pageQuery'
import Img from 'gatsby-image'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import QrCodeInterArcade from '../../assets/image/qrcodeMundoGamer.png'
import { widths } from 'src/styles/breakpoints'

import { Section, Button } from './style'

// Icons
import MoneyCheck from '@interco/icons/build-v4/orangeds/XL/money-check'
import Game from '@interco/icons/build-v4/orangeds/MD/game'
import Users from '@interco/icons/build-v4/orangeds/MD/users'
import Globe from '@interco/icons/build-v4/orangeds/MD/globe'
import Android from '@interco/icons/build-v4/orangeds/MD/android'
import useWidth from 'src/hooks/window/useWidth'

const InterArcade = () => {
  const data = pageQuery()
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth(300)

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen} setIsModal={setIsOpen} qrCode={QrCodeInterArcade}
        title='Acesse o <span class="text-orange--extra">Super App</span> e comece a jogar agora'
        subtitle='O Inter Arcade é exclusivo para clientes Inter. Para fazer sua assinatura é só acessar o QR Code abaixo, baixar o app e criar sua conta 100% digital e gratuita:'
        instructions='<span class="text-center">Disponível para aparelhos Android</span>'
      />
    </Modal>
  )

  return (
    <Section className='py-5'>
      {modal}
      <div className='container'>
        <div className='row d-flex justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-4'>
              <span className='d-xl-block'><span className='text-orange--extra'>Inter Arcade:</span> assine e</span> <span className='d-xl-block'>aproveite mais de mil</span> jogos online
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-white mb-4'>
              Uma <strong>coleção de games</strong> pra você se divertir quando e onde quiser. Sem interrupções, nem propagandas.
            </p>
            <div className='d-flex mb-3'>
              <MoneyCheck height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lg-20 text-white ml-2'>Só R$ 9,90 por mês</p>
            </div>
            <div className='d-flex mb-3'>
              <Game height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lg-20 text-white ml-2'>Mais 1.000 jogos para todas as idades </p>
            </div>
            <div className='d-flex mb-3'>
              <Users height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lg-20 text-white ml-2'>Até 5 usuários simultâneos por assinatura</p>
            </div>
            <div className='d-flex'>
              <Globe height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lg-20 text-white ml-2'>Jogos na nuvem pra não ocupar a memória do seu celular</p>
            </div>
            {
                width < widths.md ? (
                  <a
                    href='https://intergo.app/f086a555'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        element_action: 'click button',
                        element_name: 'Assinar com 10% de cashback',
                        section_name: 'Inter Arcade:  assine e aproveite mais de mil jogos online',
                        redirect_url: 'https://intergo.app/f086a555',
                      })
                    }}
                  >
                    Assinar com 10% de cashback
                  </a>
                ) : (
                  <Button
                    onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Assinar com 10% de cashback',
                      section_name: 'Inter Arcade:  assine e aproveite mais de mil jogos online',
                    })
                    }
                  }
                  >
                    Assinar com 10% de cashback
                  </Button>
                )
              }
            <div className='d-flex'>
              <p className='fs-14 lh-17 text-white mr-2'>Disponivel para aparelhos Android </p>
              <Android height={16} width={16} color='#ffffff' />
            </div>
          </div>
          <div className='col-12 col-md-6 order-first order-md-last'>
            <Img
              fluid={data.lpGamesInterArcade.fluid} alt='Várias telas exibindo os melhores jogos online para jogar no celular'
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InterArcade
