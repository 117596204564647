import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'
import BgMd from '../../assets/image/lpGamesBgMd.png'
import BgLg from '../../assets/image/lpGamesBgLg.png'
import BgXl from '../../assets/image/lpGamesBgXl.png'

export const Section = styled.section`
  background: #161616;

  a{
    background: ${orange.extra};
    border-radius: 8px;
    width: 100%;
    border: none;
    height: 48px;
    color: ${white};
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(min-width: ${breakpoints.lg}){
    width: 216px;
    margin-top: 40px;
  }

    &:focus, &:hover{
      outline: none;
      color: ${white};
    }
  }

  @media(min-width: ${breakpoints.md}){
    background: url(${BgMd});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 380px;
  }

  @media(min-width: ${breakpoints.lg}){
    background: url(${BgLg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 524px;
  }

  @media(min-width: ${breakpoints.xl}){
    background: url(${BgXl});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 713px;
  }
`
export const Button = styled.button`
  background: ${orange.extra};
  border-radius: 8px;
  width: 100%;
  border: none;
  height: 48px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 32px;

  @media(min-width: ${breakpoints.lg}){
    width: 216px;
    margin-top: 40px;
  }

  &:focus, &:hover{
    outline: none;
    color: ${white};
  }
`

export const SectionMundoGamer = styled.section`
  height: 48px;
  padding-top: 12px;
`
