import React, { useState, useLayoutEffect } from 'react'
import 'react-multi-carousel/lib/styles.css'
import Carousel, { ArrowProps } from 'react-multi-carousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import bannerJSON from 'src/assets/data/games/games.json'

import useWidth from 'src/hooks/window/useWidth'

import { OrangeIcon } from 'src/components/UI/MarkdownIcon'

import { Section, Figure, Circle, ImageSection } from './style'

type DataProps = {
  link: string;
  imagemSm: ImageProps;
  imagemMd: ImageProps;
  imagemLg: ImageProps;
  imagemXl: ImageProps;
}

type ImageProps = {
  url: string;
  name: string;
  alt: string;
}
interface IArrows extends ArrowProps {
  isMobile: boolean;
}

const devices = {
  desktop: {
    breakpoint: {
      max: 4000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 250,
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 465,
    },
    items: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
}

const ArrowRight = ({ onClick, isMobile }: IArrows) => (
  <Circle className='right' onClick={onClick} type='button'>
    <OrangeIcon color='#FF7A00' icon='chevron-right' size={isMobile ? 'SM' : 'MD'} />
  </Circle>
)

const ArrowLeft = ({ onClick, isMobile }: IArrows) => (
  <Circle className='left' onClick={onClick} type='button'>
    <OrangeIcon color='#FF7A00' icon='chevron-left' size={isMobile ? 'SM' : 'MD'} />
  </Circle>
)

const WIDTH_MD = 769

const TheBestDeals = () => {
  const [ isMobile, setIsMobile ] = useState(false)
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  useLayoutEffect(() => {
    setIsMobile(windowWidth < WIDTH_MD)
  }, [ windowWidth ])

  return (
    <Section className='pb-4 pb-lg-5' id='condicoes-especiais'>
      <div className='container mt-5'>
        <div className='col-12'>
          <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white text-center mb-3'>
            Super ofertas pra turbinar seu Mundo Gamer
          </h2>
        </div>
        <Carousel
          additionalTransfrom={0}
          arrows
          centerMode={false}
          partialVisible={!isMobile}
          draggable
          focusOnSelect={false}
          keyBoardControl
          minimumTouchDrag={80}
          responsive={devices}
          customRightArrow={<ArrowRight isMobile={isMobile} />}
          customLeftArrow={<ArrowLeft isMobile={isMobile} />}
          showDots
          dotListClass='dots'
          renderDotsOutside
          infinite
          autoPlay
          swipeable
          itemClass='item pl-2 pr-2'
        >
          { bannerJSON.map((item: DataProps, index: number) => (
            <a
              key={item.link}
              href={item.link}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: `dobra_02, p_${index + 1}`,
                  element_action: 'click banner',
                  element_name: 'null',
                  section_name: 'As melhores ofertas para turbinar seu game',
                  redirect_url: item.link,
                })
              }}
            >
              <Figure>
                <ImageSection
                  sm={item.imagemSm.url}
                  md={item.imagemMd.url}
                  lg={item.imagemLg.url}
                  xl={item.imagemXl.url}
                />
              </Figure>
            </a>
          ),
          )}
        </Carousel>
      </div>
    </Section>
  )
}

export default TheBestDeals
