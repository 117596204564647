import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type ImageProsp = {
  image: string;
}

export const Section = styled.section`
  background: #161616;
`
export const Card = styled.div`
  height: 286px;
  width: 100%;
  border-radius: 8px;
  padding: 24px 7px;
  border: 1px solid #6A6C72;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url(${(props: ImageProsp) => props.image});
  background-size: cover;


  @media (min-width: ${breakpoints.md}){
    width: 167px;
    padding: 24px 12px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 206px;
    height: 353px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 206px;
    height: 353px;
  }

`

export const Button = styled.a`
  background: ${orange.extra};
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${white};
  border: none;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;


  &:focus, &:hover {
    outline: none;
    color: ${white};
  }
`
