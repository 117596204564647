import React, { useState } from 'react'
import Img from 'gatsby-image'
import pageQuery from '../../pageQuery'
import { widths } from 'src/styles/breakpoints'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import QrCodeEstiloGamer from '../../assets/image/qrcodeMundoGamer.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Button } from './style'
import Cashback from '@interco/icons/build-v4/orangeds/MD/cashback'
import Gift from '@interco/icons/build-v4/orangeds/MD/gift'
import Game from '@interco/icons/build-v4/orangeds/MD/game'
import Smartphones from '@interco/icons/build-v4/orangeds/MD/smartphones'
import useWidth from 'src/hooks/window/useWidth'

const WhateverYourGamingStyle = () => {
  const data = pageQuery()
  const width = useWidth(300)

  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const modal = domReady && (
    <>
      <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
        <QrCodeModal
          isModal={isOpen} setIsModal={setIsOpen} qrCode={QrCodeEstiloGamer}
          instructions=''
          title='Baixe o <span class="text-orange--extra">Super App</span> e aproveite vantagens exclusivas para turbinar seu mundo gamer'
          subtitle='O QR Code vai levar você para a loja de aplicativos. Aí é só baixar o Super App do Inter e criar sua conta 100% digital e gratuita para aproveitar vantagens exclusivas.'
        />
      </Modal>
    </>
  )

  return (
    <Section
      className='py-5 d-flex align-items-center'
      role='img'
      aria-label='Homem e mulher sorridentes olhando para a tela do celular enquanto jogam games no celular.'
    >
      {modal}
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            {
              width < widths.md && (
                <Img
                  fluid={data.whateverYourGamingStyleSm.fluid}
                  className='mb-3'
                  alt='Homem e mulher sorridentes olhando para a tela do celular enquanto jogam games no celular.'
                />

              )
            }
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-50 lh-xl-50 text-white mb-4'>
              Seja qual for seu <span className='text-orange--extra'>estilo gamer</span>, o Inter tem uma vantagem pra você
            </h2>
            <div className='d-flex mb-3'>
              <div>
                <Cashback height={24} width={24} color='#FF7A00' />
              </div>
              <p className='fs-16 lh-20 text-white ml-2'>Cashback em centenas de lojas no Inter Shop</p>
            </div>
            <div className='d-flex mb-3'>
              <div>
                <Gift height={24} width={24} color='#FF7A00' />
              </div>
              <p className='fs-16 lh-20 text-white fw-600 ml-2'>Gift Cards de jogos com vantagens nas maiores plataformas gamers do mundo.</p>
            </div>
            <div className='d-flex mb-3'>
              <div>
                <Game height={24} width={24} color='#FF7A00' />
              </div>
              <p className='fs-16 lh-20 text-white fw-600 ml-2'>Mais de 1.000 jogos online de celular com o Inter Arcade.</p>
            </div>
            <div className='d-flex'>
              <div>
                <Smartphones height={24} width={24} color='#FF7A00' />
              </div>
              <p className='fs-16 lh-20 text-white fw-600 ml-2'>Conta digital 100% online e gratuita e cartão de crédito.</p>
            </div>
            {
              width < widths.md
              ? (
                <a
                  href='https://intergo.app/2f359910'
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    element_action: 'click button',
                    element_name: 'Baixar Super App',
                    section_name: 'Seja qual for seu estilo gamer, o Inter tem uma vantagem pra você',
                    redirect_url: 'https://intergo.app/2f359910',
                  })
                  }}
                >
                  Baixar Super App
                </a>
              ) : (
                <Button
                  onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  element_name: 'Baixar Super App',
                  section_name: 'Seja qual for seu estilo gamer, o Inter tem uma vantagem pra você',
                })
                  }
              }
                >
                  Baixar Super App
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhateverYourGamingStyle
