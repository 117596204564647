import styled from 'styled-components'
import { orange, white, green } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: #161616;

  .link-mobile {
    background: ${orange.extra};
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 48px;
    color: ${white};
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

  &:focus{
    outline: none;
  }

  @media(min-width: ${breakpoints.xl}){
    width: 286px;
  }
  }

  .react-multiple-carousel__arrow--right {
    right: 0
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multi-carousel-dot {
    button{
      background-color: #FFDAB7 !important;
    }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: #FF8700 !important;
    }
  }
`
export const Button = styled.button`
  background: ${orange.extra};
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 48px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 32px;

  &:focus{
    outline: none;
  }

  @media(min-width: ${breakpoints.md}){
    width: 286px;
    margin-top: 20px;
  }
`

export const Card = styled.a`
  border: 1px solid #DEDFE4;
  border-radius: 16px;
  width: 100%;
  height: 182px;
  background: ${white};
  padding: 16px 8px ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img{
    width: 136px;
  }

  .description{
    color: ${green[400]};
  }
`
