export default [
  {
    image: require('../image/appGoogle.png'),
    text: 'A partir de',
    description: '3% de cashback',
    link: 'https://shopping.inter.co/gift-card/google-play-65',
    name: 'googlePlay',
    alt: 'Gift Card Google Play',
  },
  {
    image: require('../image/freeFire.png'),
    description: 'Bônus em diamantes',
    link: 'https://shopping.inter.co/gift-card/free-fire-34',
    name: 'freeFire',
    alt: 'Gift Card Free Fire',
  },
  {
    image: require('../image/nitendo.png'),
    text: 'A partir de:',
    description: '5% de cashback',
    link: 'https://shopping.inter.co/gift-card/nintendo-cartoes-eshop-95',
    name: 'nitendo',
    aly: 'Gift Card Nintendo',
  },
  {
    image: require('../image/steam.png'),
    text: 'A partir de',
    description: '2% de cashback',
    link: 'https://shopping.inter.co/gift-card/steam-62',
    name: 'steam',
    alt: 'Gift card Steam',
  },
  {
    image: require('../image/roblox.png'),
    text: 'A partir de',
    description: '4% de cashback',
    link: 'https://shopping.inter.co/gift-card/roblox-169',
    name: 'roblox',
    alt: 'Gift card Roblox',
  },
  {
    image: require('../image/mineGraft.png'),
    text: 'A partir de',
    description: '4% de cashback',
    link: ' https://shopping.inter.co/gift-card/xbox-minecraft-legends-pc-egift-1116',
    name: 'mineGraft',
    alt: 'Gift card Minecraft',
  },
]
