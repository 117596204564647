export default [
  {
    image: require('../image/blog1.png'),
    title: 'Semana Gamer Inter: descontos épicos e Gift Cards com muito cashback',
    link: 'https://blog.inter.co/semana-gamer-2022',
  },
  {
    image: require('../image/blog2.png'),
    title: 'Melhor PC gamer 2023: melhores opções para comprar',
    link: 'https://blog.inter.co/melhor-pc-gamer',
  },
  {
    image: require('../image/blog3.png'),
    title: 'Quais os melhores jogos para Nintendo Switch?',
    link: 'https://blog.inter.co/melhores-jogos-nintendo-switch',
  },
  {
    image: require('../image/blog4.png'),
    title: 'Melhor cadeira gamer: veja as mais confortáveis',
    link: 'https://blog.inter.co/melhor-cadeira-gamer',
  },
]
