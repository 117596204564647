export default [
  {
    image: require('../image/cadeiraGamer.png'),
    text: 'Cadeira gamer',
    link: 'https://intergo.app/3931f2f3',
  },
  {
    image: require('../image/pcGamer.png'),
    text: 'PC gamer',
    link: 'https://intergo.app/eee1018c',
  },
  {
    image: require('../image/headSet.png'),
    text: 'Headset',
    link: 'https://intergo.app/ae1e4486',
  },
  {
    image: require('../image/acessorios.png'),
    text: 'Acessórios',
    link: 'https://intergo.app/91412363',
  },
  {
    image: require('../image/monitores.png'),
    text: 'Monitores',
    link: 'https://intergo.app/22710d68',
  },
  {
    image: require('../image/HubECabos.png'),
    text: 'Hub e Cabos',
    link: 'https://intergo.app/104ab760',
  },
  {
    image: require('../image/moveis.png'),
    text: 'Móveis',
    link: 'https://intergo.app/274c5df1',
  },
  {
    image: require('../image/controleGamer.png'),
    text: 'Controle gamer',
    link: 'https://intergo.app/6490b26b',
  },
]
