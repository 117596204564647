import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

import Hero from './_sections/hero/_index'
import TheBestDeals from './_sections/the-best-deals/_index'
import UpgradeYourSetup from './_sections/upgrade-your-setup/_index'
import GameGiftCards from './_sections/game-gift-cards/_index'
import InterArcade from './_sections/inter-arcade/_index'
import StayOnTopOfTheNews from './_sections/stay-on-top-of-the-news/_index'
import WhateverYourGamingStyle from './_sections/whatever-your-gaming-style/_index'
import TipsForYourGamerWorld from './_sections/tips-for-your-gamer-world/_index'
import FAQ from './_sections/faq/_index'

const ConsumerMonth = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <TheBestDeals />
        <UpgradeYourSetup />
        <GameGiftCards />
        <InterArcade />
        <StayOnTopOfTheNews />
        <WhateverYourGamingStyle />
        <TipsForYourGamerWorld />
        <FAQ />
      </Layout>
    </Wrapper>
  )
}

export default ConsumerMonth
